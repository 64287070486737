import * as React from "react";
import { Spacer } from "../system/layouts/Spacer";
import { Container } from "../system/layouts/Container";
import { Centered } from "../system/layouts/Centered";
import { Padded } from "../system/layouts/Padded";
import LiveInferenceUsageChart from "../user/LiveInferenceUsageChart";
import { Heading } from "../system/catalyst/heading";
import Statsig from "statsig-js";
import { StatsigGate } from "../../types";

interface AdminPageProps {
  token: string;
}

export default function AdminPage(props: AdminPageProps) {
  const [flags, setFlags] = React.useState<Record<string, boolean>>({});

  React.useEffect(() => {
    const gateValues = Object.values(StatsigGate).reduce(
      (acc, gate) => ({
        ...acc,
        [gate]: Statsig.checkGate(gate),
      }),
      {}
    );
    setFlags(gateValues);
  }, []);

  const toggleFlag = (gate: string) => {
    const newValue = !flags[gate];
    Statsig.overrideGate(gate, newValue);
    setFlags((prev) => ({ ...prev, [gate]: newValue }));
  };

  return (
    <Centered>
      <Padded>
        <Heading level={2}>Feature Flags</Heading>

        <Container>
          <div className="space-y-4">
            {Object.entries(flags).map(([gate, value]) => (
              <div key={gate} className="flex items-center justify-between">
                <span>{gate}</span>
                <button
                  onClick={() => toggleFlag(gate)}
                  className={`px-4 py-2 rounded ${
                    value ? "bg-green-500" : "bg-gray-300"
                  }`}
                >
                  {value ? "Enabled" : "Disabled"}
                </button>
              </div>
            ))}
          </div>
        </Container>
        <div className="mt-4">
          <Heading level={2}>Live GPU usage</Heading>
          <Spacer />
          <Container>
            <LiveInferenceUsageChart token={props.token} />
          </Container>
        </div>
      </Padded>
    </Centered>
  );
}
