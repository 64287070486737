import React from "react";
import { UserInfo } from "../../types";
import {
  GlobalDataContext,
  GlobalDataContextType,
} from "../../GlobalDataContext";
import { PDFLandingPage } from "./PDFLandingPage";
import RAGChatExplorer from "../rag/RAGChatExplorer";

interface MemRAGPlaygroundControllerProps {
  token: string;
  userInfo: UserInfo;
}

export const MemRAGPlaygroundController: React.FC<
  MemRAGPlaygroundControllerProps
> = ({ token, userInfo }: MemRAGPlaygroundControllerProps) => {
  const globalData: GlobalDataContextType =
    React.useContext<GlobalDataContextType>(GlobalDataContext);

  const [state, setState] = React.useState<{
    jobId: string | null;
    isProcessingComplete: boolean;
  }>({
    jobId: null,
    isProcessingComplete: false,
  });

  const handleJobStart = (jobId: string) => {
    console.log("Job started with ID:", jobId);
    setState({
      jobId,
      isProcessingComplete: false,
    });
  };

  const handleProcessingComplete = () => {
    console.log("Processing complete for jobId:", state.jobId);
    setState((current: any) => ({
      ...current,
      isProcessingComplete: true,
    }));
  };

  // Show chat interface only when we have both a jobId and processing is complete
  if (state.jobId && state.isProcessingComplete) {
    return (
      <RAGChatExplorer token={token} userInfo={userInfo} jobId={state.jobId} />
    );
  }

  // Otherwise show the landing page
  return (
    <PDFLandingPage
      token={token}
      onJobStart={handleJobStart}
      onProcessingComplete={handleProcessingComplete}
    />
  );
};
