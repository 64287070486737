import * as React from "react";
import { Spacer } from "../system/layouts/Spacer";
import { Description, Field, Label, RadioGroup } from "@headlessui/react";
import { Button } from "../system/atoms/Button";
import demoFile from "../../assets/earnings-calls-demo.jsonl";

const MODELS: Model[] = [
  { name: "Llama 3.1", description: "meta-llama/Meta-Llama-3.1-8B-Instruct" },
  { name: "Mistral 3", description: "mistralai/Mistral-7B-Instruct-v0.3" },
];

interface Model {
  name: string;
  description: string;
}

interface ModelSelectorProps {
  selectedModel: Model;
  setSelectedModel: React.Dispatch<React.SetStateAction<Model>>;
}

function ModelSelector({
  selectedModel,
  setSelectedModel,
}: ModelSelectorProps) {
  return (
    <div className="flex flex-col md:flex-row gap-8 w-full max-w-3xl mt-8">
      {MODELS.map((model) => (
        <div
          key={model.description}
          className={`flex-1 border-2 rounded-lg p-6 
            cursor-pointer 
            hover:bg-[#FF4B85]/10 active:bg-[#FF4B85]/20 
            hover:scale-[1.02] active:scale-[0.98] 
            hover:shadow-lg hover:shadow-[#FF4B85]/20 
            transition-all duration-150 relative
            ${
              selectedModel.name === model.name
                ? "border-[#FF4B85] bg-[#FF4B85]/10 shadow-lg shadow-[#FF4B85]/20"
                : "border-gray-700 hover:border-[#FF4B85]"
            }`}
          onClick={() => setSelectedModel(model)}
        >
          <div className="relative flex flex-col items-center justify-center">
            <div
              className={`text-lg font-bold mb-1 ${
                selectedModel.name === model.name ? "text-[#FF4B85]" : ""
              }`}
            >
              {model.name}
            </div>
            <div className="text-xs text-gray-400">{model.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

const textToTemplates = (
  text: string,
  repeatData: number,
  uploadBasePath: string
): any[] => {
  const lines = text.split("\n");
  let parts = [];

  for (let line of lines) {
    if (line) {
      for (let i = 0; i < repeatData; i++) {
        const lineObj = JSON.parse(line);
        let input = lineObj.question ? lineObj.question : lineObj.input;
        let output = lineObj.answer ? lineObj.answer : lineObj.output;
        let obj = {
          input: input,
          output: output,
        };
        if (uploadBasePath === "azure") {
          parts.push(JSON.stringify(obj) + "\n");
        } else {
          parts.push(obj);
        }
      }
    }
  }
  return parts;
};

async function submitTuningJob(
  model_name: string,
  env: string,
  token: string,
  onJobSubmit: () => void,
  setError: (error: ErrorMessage) => void
) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  let datasetBody = {
    id: "website",
    upload_base_path: "",
    args_name: "demo",
  };
  let response = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/dataset-info",
    {
      method: "POST",
      body: JSON.stringify(datasetBody),
      headers: myHeaders,
    }
  );
  let responseJson = await response.json();
  const repeatData = 1;
  const uploadBasePath = responseJson.hasOwnProperty("upload_base_path")
    ? responseJson["upload_base_path"]
    : "azure";

  const getTrainingData = async (): Promise<string> => {
    try {
      const response = await fetch(demoFile);
      return await response.text();
    } catch (err) {
      return "";
    }
  };

  const trainingParts = textToTemplates(
    await getTrainingData(),
    repeatData,
    uploadBasePath
  );

  const shuffledParts = trainingParts.sort((a, b) => 0.5 - Math.random());

  // upload data to server
  myHeaders.append("X-Client", "frontend");
  let datasetId = "";
  let datasetLocation = "";

  if (uploadBasePath === "azure") {
    const { BlobServiceClient } = await import("@azure/storage-blob");
    const sas_token = responseJson["sas_token"];
    const blobSasUrl =
      "https://laministorage.blob.core.windows.net/?" + sas_token;
    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    datasetId = responseJson.hasOwnProperty("dataset_id")
      ? responseJson["dataset_id"]
      : "";
    const blob = new Blob(shuffledParts, { type: "text/plain" });
    const containerName = "training-data";
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(
      "platform/" + datasetId
    );
    const resp = await blockBlobClient.uploadBrowserData(blob);
  } else {
    let body = {
      id: "website",
      data: shuffledParts,
      upload_base_path: uploadBasePath,
    };

    response = await fetch(
      (process.env.REACT_APP_API_URL || "") + "/v1/local-data",
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: myHeaders,
      }
    );
    responseJson = await response.json();
    datasetId = responseJson["dataset_id"];
    datasetLocation = responseJson["dataset_location"];
  }

  const body = {
    id: "website",
    model_name: model_name,
    upload_file_path: datasetLocation,
    dataset_id: datasetId,
    finetune_args: {
      args_name: "demo",
      max_steps: 60,
      r_value: 32,
      learning_rate: 0.0003,
    },
    source: { id: "website", data_type: "sample" },
  };
  fetch((process.env.REACT_APP_API_URL || "") + "/v1/train", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        onJobSubmit();
        return response.json();
      }
      throw response;
    })
    .catch((error) => {
      setError(error);
    });
}

interface TrainPageSubmitJobProps {
  token: string;
  onJobSubmit: () => void;
  env: string;
}

type ErrorMessage = string | null;

export default function TrainPageSubmitJob({
  token,
  onJobSubmit,
  env,
}: TrainPageSubmitJobProps) {
  const [selectedModel, setSelectedModel] = React.useState(MODELS[0]);
  const [error, setError] = React.useState<ErrorMessage>(null);

  return (
    <div className="flex flex-col items-center justify-center h-[100%] bg-[#0D0D0D] text-white mt-8 p-4">
      <p className="text-sm font-bold tracking-wider text-[#FF4B85] mb-2">
        MEMORY TUNING
      </p>
      <h1 className="text-4xl font-bold mb-6 text-center">
        Memory Tune MoMEs
        <br />
        <span className="text-[#FF4B85]">
          Mini Models with No Accuracy Ceiling
        </span>
      </h1>
      <p className="text-lg mb-6 text-center w-[60%] bg-gradient-to-r from-gray-800/50 to-[#FF4B85]/30 p-4 rounded-lg">
        <span className="font-bold">MoME</span>{" "}
        <span className="text-gray-400">/moʊm/</span> <i>n.</i>
        <br />
        Mixture of Memory Experts. A powerful memory tuning architecture.
      </p>

      <div className="max-w-[60%] text-lg text-gray-400 mb-12 text-center">
        Let's get started! Try Memory Tuning a model on a{" "}
        <a
          href={demoFile}
          target="_blank"
          rel="noopener noreferrer"
          className="text-[#FF4B85] underline"
        >
          sample dataset
        </a>
        .
      </div>

      <ModelSelector
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
      />
      <Spacer />
      <div
        onClick={() =>
          submitTuningJob(
            selectedModel.description,
            env,
            token,
            onJobSubmit,
            setError
          )
        }
        className="bg-[#FF4B85] rounded-lg px-6 py-3 
          cursor-pointer 
          hover:bg-[#FF4B85]/90 active:bg-[#FF4B85]/80 
          hover:scale-[1.02] active:scale-[0.98] 
          hover:shadow-lg hover:shadow-[#FF4B85]/20 
          transition-all duration-150
          text-base font-bold text-white"
      >
        Submit Memory Tuning Job
      </div>
      {error && (
        <div className="text-[#FF4B85] mt-4">
          Sorry, there was an error submitting your job. Please try again!
        </div>
      )}
    </div>
  );
}
