import React, { useState, useEffect } from "react";
import { PaperAirplaneIcon } from "@heroicons/react/20/solid";
import { EyeIcon, PencilIcon } from "@heroicons/react/24/outline";
import { UserInfo } from "../../types";

interface Message {
  role: "assistant" | "user";
  content: string;
  question?: string;
  prompt?: string;
  sources?: Array<{
    title: string;
    content: string;
  }>;
}

interface Suggestion {
  Question?: string;
  question?: string;
  Answer?: string;
  answer?: string;
}

interface RAGChatExplorerProps {
  token: string;
  userInfo: UserInfo;
  jobId: string; // Now required prop instead of from URL
}

export const RAGChatExplorer: React.FC<RAGChatExplorerProps> = ({
  token,
  userInfo,
  jobId,
}) => {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);

  // Add ref for the scrollable container
  const messagesEndRef = React.useRef<HTMLDivElement>(null);

  // Add scroll to bottom effect
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      setIsSuggestionsLoading(true);
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_API_URL || ""
          }/alpha/memory-rag/suggestions?job_id=${jobId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setSuggestions(data);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      } finally {
        setIsSuggestionsLoading(false);
      }
    };

    fetchSuggestions();
  }, [jobId, token]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const trimmedMessage = message.trim();
    if (!trimmedMessage) return;

    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL || ""}/alpha/memory-rag/completions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            prompt: trimmedMessage,
            job_id: jobId || "earnings_call",
            model_name: "meta-llama/Meta-Llama-3.1-8B-Instruct",
            rag_query_size: 3,
          }),
        }
      );

      const data = await response.json();
      console.log("API Response:", data);

      if (data.error) {
        throw new Error(data.error);
      }

      const content = data.outputs?.[0]?.output;
      if (!content) {
        throw new Error("No valid content in API response");
      }

      const newMessages: Message[] = [
        {
          role: "user",
          content: trimmedMessage,
          question: trimmedMessage,
        },
        {
          role: "assistant",
          content: content.trim(),
          prompt: data.prompt,
          sources: data.sources,
        },
      ];

      setMessages((prev) => [...prev, ...newMessages]);
      setMessage("");
    } catch (error) {
      console.error("Error submitting question:", error);
      alert(
        error instanceof Error
          ? error.message
          : "Failed to get response from API. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleQuestionClick = (question: string) => {
    if (isLoading) return;
    const trimmedQuestion = question.trim();
    if (!trimmedQuestion) return;

    setMessage(trimmedQuestion);
    handleSubmit(new Event("submit") as any);
  };

  return (
    <div className="flex flex-col h-[calc(100vh-4rem)] w-full max-w-4xl mx-auto px-4 overflow-hidden">
      {/* Main scrollable area */}
      <div className="flex-1 min-h-0 overflow-y-auto">
        <div className="space-y-6 pb-4">
          {isLoading && messages.length === 0 && (
            <div className="fixed inset-0 z-40 flex items-center justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-2 border-[#FF4B85] border-t-transparent"></div>
            </div>
          )}
          {messages.map((msg, idx) => (
            <div key={idx} className="space-y-4">
              {msg.role === "user" ? (
                <div>
                  <div className="text-[#FF4B85] text-sm mb-2">
                    Your question:
                  </div>
                  <div className="bg-[#1E1E1E] rounded p-4">{msg.question}</div>
                </div>
              ) : (
                <>
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-[#FF4B85] text-sm mb-2">Answer:</div>
                    </div>
                    <div className="bg-[#1E1E1E] rounded p-4 whitespace-pre-wrap">
                      {msg.content}
                    </div>
                  </div>

                  {msg.sources && msg.sources.length > 0 && (
                    <div>
                      <div className="text-[#FF4B85] text-sm mb-2">
                        Sources:
                      </div>
                      <div className="space-y-2">
                        {msg.sources.map((source, sourceIdx) => (
                          <div
                            key={sourceIdx}
                            className="bg-[#1E1E1E] rounded p-4"
                          >
                            <div className="font-medium mb-1">
                              {source.title}
                            </div>
                            <div className="text-sm text-gray-300">
                              {source.content}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}

          {/* Add div ref at the end of messages */}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {/* Fixed height footer */}
      <div className="py-4 bg-black">
        {suggestions.length > 0 && (
          <div className="flex overflow-x-auto mb-4 gap-2 pb-2 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-transparent">
            {suggestions.map((suggestion, idx) => (
              <button
                key={idx}
                onClick={() =>
                  handleQuestionClick(
                    suggestion.question || suggestion.Question || ""
                  )
                }
                disabled={isLoading}
                className={`
                  flex-shrink-0 
                  px-4 
                  py-2 
                  rounded 
                  text-sm 
                  whitespace-nowrap
                  bg-gradient-to-r 
                  from-[#1E1E1E] 
                  to-[#2A2A2A]
                  hover:from-[#FF4B85]/10 
                  hover:to-[#FF6B95]/10
                  transition-colors 
                  duration-200 
                  ease-in-out
                  border
                  border-transparent
                  hover:border-[#FF4B85]/20
                  ${isLoading ? "opacity-50 cursor-not-allowed" : ""}
                `}
              >
                {suggestion.question || suggestion.Question}
              </button>
            ))}
          </div>
        )}

        <form onSubmit={handleSubmit} className="flex h-12">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Ask a question about facts in your PDF..."
            disabled={isLoading}
            className="flex-grow bg-[#1E1E1E] text-white placeholder-gray-400 px-4 rounded-l border-0 focus:ring-0 disabled:opacity-50"
          />
          <button
            type="submit"
            disabled={isLoading || !message.trim()}
            className="bg-[#FF4B85] text-white px-4 rounded-r hover:opacity-90 disabled:opacity-50 transition-opacity"
          >
            <PaperAirplaneIcon className="h-5 w-5" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default RAGChatExplorer;
