export interface TrainJob {
  custom_model_name: string;
  job_id: string;
  status: string;
  model_name: string;
  start_time: string;
  resume_count: number;
  resume_limit: number;
  dataset_id: string;
}

export type ClassifierTrainingJobStatus = {
  status: string;
  model_id: string;
};

export interface ClassifierProject {
  id: string;
  project_name: string;
  user_id: string;
}
export interface ClassifierDataset {
  id: string;
  dataset_name: string;
}
export interface ClassifierModel {
  id: string;
  user_id: number;
  project_id: string;
  job_id: string;
}

export type ClassExample = {
  className: string;
  examples: string[];
  description: string;
};

export interface UserInfo {
  user_id: number;
  account_id: number;
  name: string;
  tokens: string[];
  email: string;
  dollar_credits: number;
  credits_remaining: number;
  role: string;
}

export enum StatsigGate {
  USAGE_TAB = "usage_tab",
  ADMIN_CONSOLE = "admin_console",
  LIVE_METRICS = "live_metrics",
  PRO_V2 = "pro_v2",
  THEME_SWITCHER = "theme_switcher",
  MEMORY_RAG_UI = "memory_rag_ui",
}

export type InitializePayload = {
  name: string;
  classes: Record<string, string>;
  examples: Record<string, string[]>;
  model_name: string;
};

export type InitializeResponse = {
  name: string;
  job_id: string;
};

export type AddDataPayload = {
  project_name: string;
  dataset_name: string;
  data: Record<string, string[]>;
};

export type ClassificationResult = {
  class_id: string;
  class_name: string;
  prob: number;
};

export type ClassificationHistoryItem = {
  modelId: string;
  result: ClassificationResult[];
  timestamp: string;
  prompt: string;
};

export interface ClassifierEvalPair {
  input: string & { length: NonZero };
  target: string & { length: NonZero };
}

type NonZero = number extends 0 ? never : number;

export interface ClassifierEvalSet {
  eval_data: ClassifierEvalPair[];
  eval_data_id: string;
}

export type ClassifierEvalInput = {
  testModelId: string;
  evalData: ClassifierEvalSet[];
  baseModelId?: string;
};

export type BaseMetricSet = {
  base_accuracy: number;
  base_precision: number;
  base_recall: number;
  base_f1: number;
};

export type BaseFuzzyMetricSet = BaseMetricSet & {
  base_fuzzy_accuracy: number;
  base_fuzzy_precision: number;
  base_fuzzy_recall: number;
  base_fuzzy_f1: number;
};

export type TunedMetricSet = {
  tuned_accuracy: number;
  tuned_precision: number;
  tuned_recall: number;
  tuned_f1: number;
};

export type TunedFuzzyMetricSet = TunedMetricSet & {
  tuned_fuzzy_accuracy: number;
  tuned_fuzzy_precision: number;
  tuned_fuzzy_recall: number;
  tuned_fuzzy_f1: number;
};

export type WinLossRatios = {
  base_win_loss_ratio: number;
  tuned_win_loss_ratio: number;
};

export interface ClassifierEvaluationResult {
  eval_job_id: string;
  eval_data_id: string;
  metrics: TunedMetricSet &
    Partial<BaseFuzzyMetricSet> &
    Partial<TunedFuzzyMetricSet> &
    Partial<WinLossRatios>;
  status: string;
  predictions: Array<{
    input: string;
    target: string;
    test_output: string;
    base_output: string | null;
  }>;
}
