import Statsig from "statsig-js";

export const initializeStatsig = async (
  user_email: string,
  statsig_client_public_key: string,
  local_mode: boolean
) => {
  await Statsig.initialize(
    statsig_client_public_key,
    { email: user_email },
    { localMode: local_mode }
  );
  // use to override gates for local development
  // (you can also use the Admin UI (localhost:3000/admin) if you're running locally)
  // if you use this and then comment it out, you need to clear your browser's local storage to reset the gate value to false
  // Statsig.overrideGate(StatsigGate.THEME_SWITCHER, true);
};
