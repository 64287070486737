import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "./ProgressBar";
import previewImage from "../../assets/wells-fargo-pdf-preview.png";

// Simple icon components
const UploadIcon = ({ className = "" }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
    />
  </svg>
);

const DocumentIcon = ({ className = "" }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
    />
  </svg>
);

// Add these new icon components near the top with the other icons
const DocsIcon = ({ className = "" }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
    />
  </svg>
);

const EmailIcon = ({ className = "" }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    />
  </svg>
);

interface PDFLandingPageProps {
  token: string;
  onJobStart: (jobId: string) => void;
  onProcessingComplete: () => void;
}

export const PDFLandingPage = ({
  token,
  onJobStart,
  onProcessingComplete,
}: PDFLandingPageProps) => {
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [jobId, setJobId] = useState<string | null>(null);

  const handleError = () => {
    setIsUploading(false);
    setJobId(null);
    setError("Processing failed. Please try again. Check console for details.");
  };

  const resetState = () => {
    setIsUploading(false);
    setJobId(null);
    setError(null);
  };

  const handleFileUpload = async (file: File) => {
    resetState();
    setIsUploading(true);
    const formData = new FormData();
    formData.append("files", file);
    formData.append("model_name", "meta-llama/Meta-Llama-3.1-8B-Instruct");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL || ""}/alpha/memory-rag/train`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      const data = await response.json();
      setJobId(data.job_id);
      onJobStart(data.job_id);
    } catch (error) {
      console.error("Error uploading file:", error);
      handleError();
    }
  };

  const handleSamplePDF = async () => {
    resetState();
    setIsUploading(true);
    try {
      const url = `${
        process.env.REACT_APP_API_URL || ""
      }/alpha/memory-rag/earnings_call_demo`;
      console.log("Requesting URL:", url); // Log the URL being called

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Response status:", response.status);
        console.error("Response text:", errorText);
        throw new Error(
          `Sample PDF request failed: ${response.status} - ${errorText}`
        );
      }

      const data = await response.json();
      setJobId(data.job_id);
      onJobStart(data.job_id);
    } catch (error) {
      console.error("Error requesting sample PDF:", error);
      setError(
        error instanceof Error ? error.message : "Unknown error occurred"
      );
      handleError();
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-[100%] bg-[#0D0D0D] text-white mt-8 p-4">
      <p className="text-sm font-bold tracking-wider text-[#FF4B85] mb-2">
        MEMORY RAG
      </p>
      <h1 className="text-4xl font-bold mb-6 text-center">
        <div className="relative">
          <span className="bg-gradient-to-r from-[#FF4B85] to-orange-500 bg-clip-text text-transparent">
            Still Cooking 🧑‍🍳
          </span>
          <br />
          <br />
          <span className="text-2xl text-white-400">
            — Meanwhile —
            <div className="flex gap-4 mt-6">
              <button
                onClick={() =>
                  window.open("https://docs.lamini.ai/memory_rag/", "_blank")
                }
                className="flex-1 flex items-center justify-center gap-2 px-4 py-3 bg-[#FF4B85]/10 hover:bg-[#FF4B85]/20 
                  border-2 border-[#FF4B85] rounded-lg transition-all duration-150 group hover:scale-105 active:scale-95"
              >
                <DocsIcon className="w-5 h-5 text-[#FF4B85]" />
                <span className="text-lg text-white whitespace-nowrap">
                  Docs
                </span>
                <span className="text-[#FF4B85] group-hover:translate-x-1 transition-transform">
                  →
                </span>
              </button>

              <a
                href="mailto:info@lamini.ai?subject=Curious%20about%20Lamini%20Memory%20RAG%20demo&body=Hi%20Lamini%20team%2C%0A%0A%20I'm%20interested%20in%20the%20Memory%20RAG%20demo%20on%20%3CYOUR%20USE%20CASE%3A%20text%20to%20SQL%20and%20factual%20reasoning%3E.%0A%0APlease%20let%20me%20know%20how%20to%20schedule%20one%20%3A)%0A%0AThanks%20so%20much!%0A%3CYOUR%20NAME%3E"
                className="flex-1 flex items-center justify-center gap-2 px-4 py-3 bg-[#FF4B85]/10 hover:bg-[#FF4B85]/20 
                  border-2 border-[#FF4B85] rounded-lg transition-all duration-150 group hover:scale-105 active:scale-95"
              >
                <EmailIcon className="w-5 h-5 text-[#FF4B85]" />
                <span className="text-lg text-white whitespace-nowrap">
                  Fun demo
                </span>
                <span className="text-[#FF4B85] group-hover:translate-x-1 transition-transform">
                  →
                </span>
              </a>
            </div>
          </span>
          <br />
          <div className="text-xl bg-gradient-to-r from-[#FF4B85] to-orange-500 bg-clip-text text-transparent mt-4">
            Our llamas are checking{" "}
            <a
              href="mailto:info@lamini.ai"
              className="text-[#FFFFFF] hover:text-[#FF4B85]/80 underline"
            >
              info@lamini.ai
            </a>{" "}
            for your requests.
          </div>
        </div>
      </h1>

      <div className="w-full border-b-2 border-dotted border-[#FF4B85]/30 my-8"></div>

      <p className="text-sm font-bold tracking-wider text-[#FF4B85] mb-2">
        INTERFACE COMING SOON
      </p>
      <h1 className="text-4xl font-bold mb-6 text-center">
        Build RAG Agents with
        <br />
        <span className="text-[#FF4B85]">90% Fact Accuracy</span>
      </h1>
      <p className="text-lg mb-6 text-center w-[60%] bg-gradient-to-r from-gray-800/50 to-[#FF4B85]/30 p-4 rounded-lg">
        <span className="font-bold">fact</span>{" "}
        <span className="text-gray-400">/fakt/</span> <i>n.</i>
        <br />
        Verifiable information like numbers, IDs, specific terms.
      </p>
      <p className="text-lg mb-6 text-center w-[60%] text-gray-400">
        LLMs like ChatGPT aren't taught to get facts <i>precisely</i> right.
        Complex RAG techniques can only limit hallucinations <i>a little</i>.
      </p>

      {error ? (
        <div className="w-full max-w-xl">
          <div className="bg-red-950/20 border border-red-500/50 rounded-lg p-4 text-center">
            <div className="text-red-500 font-medium mb-2">{error}</div>
            <button
              onClick={resetState}
              className="text-sm text-red-400 hover:text-red-300"
            >
              Try again
            </button>
          </div>
        </div>
      ) : isUploading && jobId ? (
        <div className="w-full max-w-xl">
          <ProgressBar
            jobId={jobId}
            token={token}
            onComplete={onProcessingComplete}
            onError={handleError}
          />
        </div>
      ) : (
        <>
          <div className="flex flex-col md:flex-row gap-8 w-full max-w-3xl mt-8">
            <div
              className={`flex-1 border-2 ${
                isDragging
                  ? "border-[#FF4B85] bg-[#FF4B85]/10"
                  : "border-[#FF4B85]"
              } 
              rounded-lg p-6 
              relative 
              cursor-pointer 
              hover:bg-[#FF4B85]/10 active:bg-[#FF4B85]/20 
              hover:scale-[1.02] active:scale-[0.98] 
              hover:shadow-lg hover:shadow-[#FF4B85]/20 
              transition-all duration-150`}
              onClick={() => document.getElementById("fileInput")?.click()}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <div
                className={`absolute inset-2 border-2 border-dotted border-[#FF4B85]/50 rounded-lg ${
                  isDragging ? "scale-95 opacity-100" : "scale-100 opacity-50"
                } transition-all duration-300`}
              ></div>

              <div className="relative flex flex-col items-center justify-center">
                <input
                  id="fileInput"
                  type="file"
                  className="hidden"
                  accept=".pdf"
                  onChange={(e) =>
                    e.target.files?.[0] && handleFileUpload(e.target.files[0])
                  }
                />
                <UploadIcon
                  className={`w-5 h-5 mb-2 text-[#FF4B85] animate-bounce transition-transform duration-300 ${
                    isDragging ? "scale-110" : ""
                  }`}
                />
                <div className="text-lg font-bold mb-1">
                  Upload PDF{" "}
                  <span className="text-xs text-gray-400">Text Only</span>
                </div>
                <div className="text-xs text-gray-400">
                  {isDragging
                    ? "Drop Your File Here"
                    : "Drag-and-drop or Click to Select"}
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div
                className="border-2 border-[#FF4B85] rounded-lg p-6 flex flex-col items-center justify-center cursor-pointer 
                hover:bg-[#FF4B85]/10 active:bg-[#FF4B85]/20 
                hover:scale-[1.02] active:scale-[0.98] 
                hover:shadow-lg hover:shadow-[#FF4B85]/20 
                transition-all duration-150
                relative group"
                onClick={handleSamplePDF}
              >
                <DocumentIcon className="w-5 h-5 mb-2 text-[#FF4B85] animate-pulse" />
                <div className="text-lg font-bold mb-1">Select Sample PDF</div>
                <div className="text-xs text-gray-400">
                  Wells Fargo Earnings Call
                </div>

                {/* Preview tooltip */}
                <div className="absolute hidden group-hover:block bottom-full left-1/2 transform -translate-x-1/2 mb-2 p-2 bg-gray-900 rounded-lg shadow-xl z-10">
                  <img
                    src={previewImage}
                    alt="PDF Preview"
                    style={{ width: "800px" }}
                    className="rounded border border-gray-700"
                  />
                </div>
              </div>
              <div className="text-xs text-gray-500 mt-2 text-center">
                <a
                  href="https://seekingalpha.com/symbol/WFC/earnings/transcripts?source=acquisition_campaign_google&campaign_id=21764264540&internal_promotion=true&utm_source=google&utm_medium=cpc&utm_campaign=21764264540&utm_term=170599034800%5Edsa-1485125212058%5E%5E715438294508%5E%5E%5Eg&hsa_acc=5428037747&hsa_cam=21764264540&hsa_grp=170599034800&hsa_ad=715438294508&hsa_src=g&hsa_tgt=dsa-1485125212058&hsa_kw=&hsa_mt=&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=Cj0KCQiA4rK8BhD7ARIsAFe5LXLAfVw2yrRCjYA9QNkynTt5m0wCdMAQoPVzhbbajVK2O2cGjghufooaAjjPEALw_wcB"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-400 transition-colors"
                >
                  Source: Seeking Alpha
                </a>
              </div>
            </div>
          </div>

          {/* New disclaimer text */}
          <div className="text-sm mt-8 text-center w-full px-8">
            <div className="bg-gray-900/50 p-6 rounded-lg">
              <p className="bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                Achieving 90%+ fact accuracy takes ~5 iterations. Runtime up to
                30s of embed-time compute per page.
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
