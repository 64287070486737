import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/system/catalyst/button";
import { Divider } from "../../components/system/catalyst/divider";
import { Heading } from "../../components/system/catalyst/heading";
import { Strong, Text } from "../../components/system/catalyst/text";
import { Centered } from "../../components/system/layouts/Centered";
import { ClassifierProject } from "../../types";
import {
  useClassifierProjects,
  useProjectJobs,
} from "../hooks/useClassifierProjects";
import {
  ArrowsPointingOutIcon,
  LightBulbIcon,
} from "@heroicons/react/20/solid";
import { TagIcon } from "@heroicons/react/24/outline";
import { ProjectListEntry } from "./ProjectListEntry";

export const ClassifierProjectList = () => {
  const { data: projectPages } = useClassifierProjects();
  const navigate = useNavigate();

  const NewProjectButton = () => (
    <Button
      color="lamini-primary"
      onClick={() => navigate("/classify/new")}
      className="hover:scale-[1.02] active:scale-[0.98] transition-all duration-150"
    >
      New Project
    </Button>
  );

  if (!projectPages)
    return (
      <Centered>
        <Text>Loading...</Text>
      </Centered>
    );

  if (!projectPages?.pages?.length || projectPages.pages[0]?.length === 0)
    return (
      <div className="flex flex-col items-center justify-center h-[100%] bg-[#0D0D0D] text-white mt-8 p-4">
        <p className="text-sm font-bold tracking-wider text-[#FF4B85] mb-2">
          MEMORY CLASSIFIER
        </p>
        <h1 className="text-4xl font-bold mb-6 text-center">
          Build High-Accuracy Mini Classifiers with
          <br />
          <span className="text-[#FF4B85]">Classifier Agent Toolkit (CAT)</span>
        </h1>

        <div className="w-[60%] bg-gradient-to-r from-gray-800/50 to-[#FF4B85]/30 p-8 rounded-lg space-y-4">
          <div className="flex items-center gap-3">
            <div className="rounded-lg bg-blue-500/10 p-2">
              <TagIcon className="size-4 text-[#FF4B85]" />
            </div>
            <Text>Label data with custom categories</Text>
          </div>

          <div className="flex items-center gap-3">
            <div className="rounded-lg bg-yellow-500/10 p-2">
              <LightBulbIcon className="size-4 text-[#FF4B85]" />
            </div>
            <Text>Detect user intent automatically</Text>
          </div>

          <div className="flex items-center gap-3">
            <div className="rounded-lg bg-emerald-500/10 p-2">
              <ArrowsPointingOutIcon className="size-4 text-[#FF4B85]" />
            </div>
            <Text>Route requests to the right place</Text>
          </div>
        </div>

        <div className="mt-8">
          <NewProjectButton />
        </div>
      </div>
    );

  return (
    <div className="flex flex-col items-center justify-center h-[100%] bg-[#0D0D0D] text-white mt-8 p-4">
      <div className="w-full max-w-3xl">
        <div className="flex justify-between items-center mb-6">
          <div>
            <p className="text-sm font-bold tracking-wider text-[#FF4B85] mb-2">
              MEMORY CLASSIFIER
            </p>
            <Heading level={1} className="text-3xl">
              Classifier Projects
            </Heading>
          </div>
          <NewProjectButton />
        </div>
        <div className="space-y-4 bg-gradient-to-r from-gray-800/50 to-[#FF4B85]/30 p-6 rounded-lg">
          {projectPages?.pages.flatMap((projects, pageIndex) =>
            projects.map((project: ClassifierProject) => (
              <div key={`${pageIndex}-${project.id}`}>
                <ProjectListEntry project={project} />
                <Divider className="border-gray-700" />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
