import React, { useEffect, useState } from "react";

interface ProgressBarProps {
  jobId: string;
  token: string;
  onComplete: () => void;
  onError: () => void;
}

export const ProgressBar = ({
  jobId,
  token,
  onComplete,
  onError,
}: ProgressBarProps) => {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("");
  const [detailedStatus, setDetailedStatus] = useState("");
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const checkStatus = async () => {
      try {
        // Check main status
        const response = await fetch(
          `${process.env.REACT_APP_API_URL || ""}/alpha/memory-rag/status`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              job_id: jobId,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to check status");
        }

        const data = await response.json();
        setStatus(data.status);

        // Check training logs for detailed status
        const logsResponse = await fetch(
          `${
            process.env.REACT_APP_API_URL || ""
          }/alpha/memory-rag/training_log/${jobId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (logsResponse.ok) {
          const logs = await logsResponse.json();
          // Check for error logs
          const hasErrorLog = logs.some(
            (log: string) =>
              log.includes("ERROR:") ||
              log.includes("error:") ||
              log.includes("Exception:")
          );

          if (hasErrorLog) {
            clearInterval(intervalId);
            setHasError(true);
            onError();
            return;
          }

          const infoLogs = logs.filter((log: string) =>
            log.includes("<--UIMESSAGE-->")
          );
          const lastLog = infoLogs[infoLogs.length - 1];
          if (lastLog) {
            // Extract the meaningful part of the log message
            const message =
              lastLog
                .split("<--UIMESSAGE-->")
                .slice(1)[0]
                .replace("</--UIMESSAGE-->", "")
                .trim() || "";
            if (message) {
              setDetailedStatus(message);
            }
          }
        }

        // Update progress based on status and logs
        switch (data.status.toLowerCase()) {
          case "created":
            setProgress(10);
            break;
          case "queued":
            setProgress(20);
            break;
          case "running":
            setProgress(50);
            break;
          case "loading data":
            setProgress(60);
            break;
          case "training model":
            setProgress(70);
            break;
          case "evaluating model":
            setProgress(80);
            break;
          case "saving model":
            setProgress(90);
            break;
          case "completed":
            setProgress(100);
            clearInterval(intervalId);
            onComplete();
            break;
          case "failed":
          case "cancelled":
          case "timed out":
            clearInterval(intervalId);
            setHasError(true);
            onError();
            break;
          default:
            setProgress(0);
        }
      } catch (error) {
        console.error("Error checking status:", error);
        clearInterval(intervalId);
        setHasError(true);
        onError();
      }
    };

    intervalId = setInterval(checkStatus, 1000);
    return () => clearInterval(intervalId);
  }, [jobId, token, onComplete, onError]);

  // Hide system messages (those starting with lowercase) and all uppercase messages
  // TODO: We might want to make this filtering more sophisticated later
  const shouldShowMessage = (msg: string) =>
    /^[A-Z]/.test(msg) && !/^[A-Z]+$/.test(msg);

  if (hasError) {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
        <div className="bg-[#1A1A1A] rounded-xl p-6 border border-[#2A2A2A] max-w-md w-full">
          <h3 className="text-xl font-semibold text-red-500 mb-2">
            Processing Failed
          </h3>
          <p className="text-gray-400">
            An error occurred during processing. Please try again.
          </p>
          <button
            onClick={onError}
            className="mt-4 w-full px-4 py-2 bg-red-500 rounded-lg hover:bg-red-600 transition-colors duration-200 text-white font-medium"
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-4xl mx-auto">
      <div className="w-full bg-[#1A1A1A] border border-[#2A2A2A] rounded-lg p-6">
        <div className="w-full bg-[#2A2A2A] rounded-full h-2.5 overflow-hidden">
          <div
            className="bg-[#FF4B85] h-full rounded-full transition-all duration-500 ease-in-out hover:brightness-110"
            style={{ width: `${progress}%` }}
          />
        </div>
        {(detailedStatus || status) &&
          shouldShowMessage(detailedStatus || status) && (
            <div className="text-sm mt-3 text-center font-medium bg-gradient-to-r from-[#FF4B85] via-[#FF8469] to-[#FF4B85] text-transparent bg-clip-text animate-gradient">
              {detailedStatus || status}
            </div>
          )}
      </div>
    </div>
  );
};
